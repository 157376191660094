import React, { useState } from 'react';
import { Form, InputGroup, Button, Dropdown, Container } from 'react-bootstrap';
import './SmallSearchBar.css';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';
import { useNavigate } from 'react-router-dom';
import FloatingAlert from './FloatingAlert';


const SmallSearchBar = () => {

  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState('All Filters');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${API_ENDPOINTS.SEARCH}?query=${query}&filter=${filter.toLowerCase()}`);
      const { type, data } = response.data;

      if (type === 'address') {
        navigate(`/address/${data}`);
      } else if (type === 'block') {
        // Uncomment this when the single block view route is implemented
        // navigate(`/block/${data}`);
        console.log(`Block found: ${data}. Route not implemented yet.`);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setAlertMessage('No results found');
        setShowAlert(true);
      } else {
        console.error('Error performing search:', error);
        setAlertMessage('An error occurred while searching');
        setShowAlert(true);
      }
    }
  };
  return (

    <div className="top-bar">
      <Container>
        <Form onSubmit={handleSearch}>
          <InputGroup className='smallSearchBar'>
            <Dropdown className='smallSearchBarDropDown' >
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              {filter}

              </Dropdown.Toggle>
              <Dropdown.Menu>
              <Dropdown.Item onClick={() => setFilter('All Filters')}>All Filters</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter('Address')}>Address</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter('Block')}>Block</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Form.Control
              type="text"
              placeholder="Search by Address / Block "
              onChange={(e) => setQuery(e.target.value)}
              value={query}

            />
            <Button variant="primary" type="submit">
              <i className="fas fa-search"></i>
            </Button>
          </InputGroup>
        </Form>

        <FloatingAlert
        message={alertMessage}
        show={showAlert}
        onHide={() => setShowAlert(false)}
      />
      </Container>
    </div>

  );
};

export default SmallSearchBar;