import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import Home from './Components/Home';
import Transactions from './Components/Transactions';
import Validators from './Components/Validators';
import Blocks from './Components/Blocks';
import SingleBlock from './Components/SingleBlock';
import SingleTransaction from './Components/SingleTransaction';
import Address from './Components/Address';
import ValidatorListing from './Components/ValidatorListing';
import ContactUs from './Components/ContactUs';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Terms from './Components/Terms';


function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="txs" element={<Transactions />} />
          <Route path="validators" element={<Validators />} />
          <Route path="blocks" element={<Blocks />} />
          <Route path="block/:blockId" element={<SingleBlock />} />
          <Route path="tx/:trnxHash" element={<SingleTransaction />} />
          <Route path="address/:address" element={<Address />} />
          <Route path="stake" element={<ValidatorListing />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
         
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;