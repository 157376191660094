import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './Blocks.css';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

const Blocks = () => {
  const [blocks, setBlocks] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 25,
    total: 0,
  });

  const fetchBlocks = async (page) => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.BLOCKS}?page=${page}&per_page=${pagination.perPage}`);
      setBlocks(response.data.blocks);
      setPagination({
        currentPage: response.data.current_page,
        lastPage: response.data.last_page,
        perPage: response.data.per_page,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching blocks:', error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.lastPage) {
      fetchBlocks(newPage);
    }
  };

  const formatAge = (timestamp) => {
    try {
      const date = parseISO(timestamp);
      const formatted = formatDistanceToNow(date, { addSuffix: true });
      return formatted.replace('about ', ''); // Remove 'about ' from the string

    } catch (error) {
      console.error('Error formatting date:', error);
      return timestamp; // Return original timestamp if parsing fails
    }
  }

  useEffect(() => {
    fetchBlocks(1);
  }, []);
  return (
    <Container fluid className="block-container p-1">
      <h2>Blocks</h2>
      <div className="block-widget">
        <div className="widget-header">
          <div className="num_trnx_found">
          {/* Block #{pagination.total} to #{Math.max(1, pagination.total - pagination.perPage + 1)} (Total of {pagination.total} blocks) */}
          Block  (Total of {pagination.total} blocks)
          </div>
        </div>
        <div className="block-list ">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Block</th>
                <th>Age</th>
                <th>Txn</th>
                <th>Validator</th>

              </tr>
            </thead>
            <tbody>
              {blocks.map((block) => (
                <tr key={block.block_number}>
                  <td className="block-number"><Link to={"/block/" + block.block_number } className="see-more-link">{block.block_number}</Link></td>
                  <td className="age">{formatAge(block.created_at)}</td>
                  <td className="txn-count">{block.num_of_trnx_confirmed}</td>
                  <td className="validator"><Link to="#" className="see-more-link">{block.block_validator}</Link></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="widget-footer">
          <div className="table-pagination">
            <span className="pagination-item" onClick={() => handlePageChange(1)}>First</span>
            <span className="pagination-item" onClick={() => handlePageChange(pagination.currentPage - 1)}>&lt;</span>
            <span className="pagination-text">Page {pagination.currentPage} of {pagination.lastPage}</span>
            <span className="pagination-item" onClick={() => handlePageChange(pagination.currentPage + 1)}>&gt;</span>
            <span className="pagination-item" onClick={() => handlePageChange(pagination.lastPage)}>Last</span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Blocks;