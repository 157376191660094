import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';
import './Validators.css';
import numeral from 'numeral';

const Validators = () => {
  const [validators, setValidators] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    total: 0,
  });

  useEffect(() => {
    fetchValidators(1);
  }, []);

  const fetchValidators = async (page) => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.VALIDATORS}?page=${page}&per_page=${pagination.perPage}`);
      setValidators(response.data.validators);
      setPagination({
        currentPage: response.data.current_page,
        lastPage: response.data.last_page,
        perPage: response.data.per_page,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching validators:', error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.lastPage) {
      fetchValidators(newPage);
    }
  };

  return (
    <Container fluid className="validators-container mt-2 p-1">
      <h2>Stakers Top Leaderboard</h2>
      <div className="validators-widget">
        <div className="validators-list">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Address</th>
                <th>Coin Power</th>
                <th>Stake Power</th>
                <th>Frequency of Stake</th>
              </tr>
            </thead>
            <tbody>
              {validators.map((validator, index) => (
                <tr key={validator.miner_id}>
                  <td>#{(pagination.currentPage - 1) * pagination.perPage + index + 1}</td>
                  <td className="address txn-hash">
                    <Link className='see-more-link' to={`/address/${validator.wallet_address}`}>
                      Validator: {validator.validator}
                    </Link>
                  </td>
                  <td className="coin-power value">{numeral(validator.coin_power).format("0,0.0000")} EKA</td>
                  <td className="stake-power value">${numeral(validator.stake_power).format("0,0.0000")}</td>
                  <td className="frequency value text-align-center">{validator.frequency}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="widget-footer">
          <div className="table-pagination">
            <span className="pagination-item" onClick={() => handlePageChange(1)}>First</span>
            <span className="pagination-item" onClick={() => handlePageChange(pagination.currentPage - 1)}>&lt;</span>
            <span className="pagination-text">Page {pagination.currentPage} of {pagination.lastPage}</span>
            <span className="pagination-item" onClick={() => handlePageChange(pagination.currentPage + 1)}>&gt;</span>
            <span className="pagination-item" onClick={() => handlePageChange(pagination.lastPage)}>Last</span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Validators;