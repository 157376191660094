import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import './Transactions.css';
import { Eye } from 'lucide-react';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';
import { differenceInMinutes, differenceInSeconds, formatDistanceToNow, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import InfoCircleIcon from './InfoCircleIcon';
import numeral from 'numeral';



const AdditionalInfo = ({ info, onClose }) => (
  <div className="additional-info-container">
    <div className="additional-info-header">
      <span>Additional Info</span>
      <button onClick={onClose}>&times;</button>
    </div>
    <div className="additional-info-content">
      <div className="info-row">
        <span className="info-label">Status:</span>
        <span className="info-value">
          <span className="status-success">success</span> ({info.block_number} Block Confirmations)
        </span>
      </div>
      <div className="info-row">
        <span className="info-label">Transition Fee:</span>
        <span className="info-value">{info.transaction_fee} EKA (${info.transaction_fee_usd})</span>
      </div>
     
      <div className="info-row">
        <Link to={"/tx/" + info.hash} className="see-more-link">See More Detail</Link>
      </div>
    </div>
  </div>
);



const Transactions = () => {
  const [selectedTxInfo, setSelectedTxInfo] = useState(null);

  const [transactions, setTransactions] = useState([]);
  const [selectedTx, setSelectedTx] = useState(null);
  const [infoPosition, setInfoPosition] = useState({ top: 0, left: 0 });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    perPage: 25,
    total: 0,
  });
  const infoRef = useRef(null);

  const fetchTransactions = async (page) => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.TRANSACTIONS}?page=${page}&per_page=${pagination.perPage}`);
      setTransactions(response.data.transactions);
      setPagination({
        currentPage: response.data.current_page,
        lastPage: response.data.last_page,
        perPage: response.data.per_page,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };


  const formatAge = (timestamp) => {
    try {
      const date = parseISO(timestamp);
      const formatted = formatDistanceToNow(date, { addSuffix: false });
      return formatted.replace('about ', ''); // Remove 'about ' from the string

    } catch (error) {
      console.error('Error formatting date:', error);
      return timestamp; // Return original timestamp if parsing fails
    }
  }



  useEffect(() => {
    fetchTransactions(1);
  },[]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.lastPage) {
      fetchTransactions(newPage);
    }
  };



  const handleEyeClick = async (event, hash) => {
    event.stopPropagation();
    const rect = event.target.getBoundingClientRect();
    setInfoPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX
    });

    try {
      const response = await axios.get(`${API_ENDPOINTS.TRANSACTION}/${hash}`);
      setSelectedTxInfo(response.data);
    } catch (error) {
      console.error('Error fetching transaction details:', error);
      setSelectedTxInfo(null);
    }
  };

  const handleClickOutside = (event) => {
    if (infoRef.current && !infoRef.current.contains(event.target)) {
      setSelectedTx(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Container fluid className="transactions-container mt-2 p-1">
      <h2>Transactions</h2>
      <div className="transactions-widget">
        <div className="widget-header">
          <div className="num_trnx_found">
            More than &gt; {pagination.total} transactions found
            <br />
            (Showing {pagination.perPage} records per page)
          </div>
        </div>
        <div className="transactions-list ">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th></th>
                <th>Txn Hash</th>
                <th>Method <InfoCircleIcon /></th>
                <th>Block</th>
                <th>Age</th>
                <th>From</th>
                <th>To</th>
                <th>Value</th>
                <th>Txn Fee</th>
              </tr>
            </thead>            
            <tbody>
              {transactions.map((tx, index) => (
                <tr key={tx.hash}>
                  <td className='see-additional-info'>
                    <Eye
                      size={18}
                      onClick={(e) => handleEyeClick(e, tx.hash)}
                      style={{ cursor: 'pointer' }}
                    />
                  </td>
                  <td className="txn-hash"><Link to={"/tx/" + tx.hash} className="see-more-link">{tx.hash.slice(0,17)}...</Link></td>
                  <td className="method"><span className="method-tag">{tx.method}</span></td>
                  <td className="block"><Link to={"/block/" + tx.block} className="see-more-link">{tx.block}</Link></td>
                  <td className="age">{formatAge(tx.created_at)}</td>
                  <td className="address"><Link to={"/address/" + tx.from} className="see-more-link">{tx.from.slice(0,8)}...{tx.from.slice(-8)}</Link></td>
                  <td className="address"><Link to={"/address/" + tx.to} className="see-more-link">{tx.to.slice(0,8)}...{tx.to.slice(-8)}</Link> </td>
                  <td className="value">{numeral(tx.value).format("0,0.0000")} EKA</td>
                  <td className="fee">{numeral(tx.txn_fee).format("0,0.0000")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="widget-footer">
          <div className="table-pagination">
            <span className="pagination-item" onClick={() => handlePageChange(1)}>First</span>
            <span className="pagination-item" onClick={() => handlePageChange(pagination.currentPage - 1)}>&lt;</span>
            <span className="pagination-text">Page {pagination.currentPage} of {pagination.lastPage}</span>
            <span className="pagination-item" onClick={() => handlePageChange(pagination.currentPage + 1)}>&gt;</span>
            <span className="pagination-item" onClick={() => handlePageChange(pagination.lastPage)}>Last</span>
          </div>
        </div>
      </div>

      {selectedTxInfo && (
        <div
          ref={infoRef}
          className="floating-info"
          style={{ top: infoPosition.top, left: infoPosition.left }}
        >
          <AdditionalInfo
            info={selectedTxInfo}
            onClose={() => setSelectedTxInfo(null)}
          />
        </div>
      )}
    </Container>
  );
};

export default Transactions;