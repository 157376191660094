import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Clock, Copy, QrCode } from 'lucide-react';
import './Address.css';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';
import { Link } from 'react-router-dom';
import PixelAvatarIcon from './PixelAvatarIcon';
import { differenceInMinutes, differenceInSeconds, formatDistanceToNow, parseISO } from 'date-fns';



const DetailItem = ({ title, value, additionalInfo, icon, copyable, onCopy, className }) => (
    <Row className={`detail-item ${className || ''}`}>
        <Col xs={12} md={3} className="detail-title">
            {icon && <span className="icon">{icon}</span>}
            {title}
        </Col>
        <Col xs={12} md={9} className="detail-value">
            <div className="value-wrapper">
                <span>{value}</span>
                {copyable && (
                    <button className="copy-button" onClick={() => onCopy(value)}>
                        <Copy size={16} />
                    </button>
                )}
            </div>
            {additionalInfo && <div className="additional-info">{additionalInfo}</div>}
        </Col>
    </Row>
);



const Address = () => {
    const { address } = useParams();
    const [addressData, setAddressData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);

    const formatAge = (timestamp) => {
        try {
          const date = parseISO(timestamp);
          const formatted = formatDistanceToNow(date, { addSuffix: false });
          return formatted.replace('about ', ''); // Remove 'about ' from the string
    
        } catch (error) {
          console.error('Error formatting date:', error);
          return timestamp; // Return original timestamp if parsing fails
        }
      }

    useEffect(() => {
        const fetchAddressData = async () => {
            try {
                const response = await axios.get(`${API_ENDPOINTS.ADDRESS}/${address}?page=${page}`);
                setAddressData(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch address data');
                setLoading(false);
            }
        };

        fetchAddressData();
    }, [address, page]);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!addressData) return <div>Address not found</div>;

    return (
        <Container fluid className="transaction-container">
            <div className="address-title-container">
                <div className="address-header">
                    <div className="address-icon">
                        <PixelAvatarIcon />
                    </div>
                    <h2>Address</h2>
                </div>
                <p className="address-value">{address}</p>
                <div className="address-actions">
                    <button className="icon-button" onClick={() => copyToClipboard(address)} title="Copy Address">
                        <Copy size={16} />
                    </button>
                    <button className="icon-button"  title="Show QR Code">
                        <QrCode size={16} />
                    </button>
                </div>
            </div>
            <div className="transaction-card">
                <DetailItem title="Overview" />
                <hr className="divider" />
                <DetailItem
                    title="EKA BALANCE:"
                    value={`${addressData.balance} EKA`}
                />
                <DetailItem
                    title="EKA VALUE:"
                    value={
                        <>
                            ${addressData.balance_usd} (@ $0.119/EKA)
                        </>

                    }
                />

                <DetailItem
                    title="Token:"
                    value={
                        <>
                            <select name="" id="" >
                                <option value="1 OptCode" disabled selected>1 OptCode</option>
                            </select>
                        </>

                    }
                />
            </div>

            <div className="transactions-widget mt-5">
                <div className="widget-header">
                    <div className="num_trnx_found">
                        {addressData.transactions.total} transactions found
                        <br />
                        (Showing {addressData.transactions.per_page} records per page)
                    </div>
                </div>
                <div className="transactions-list ">
                    <table className="table ">
                        <thead>
                            <tr>
                                <th>Txn Hash</th>
                                <th>Method</th>
                                <th>Block</th>
                                <th>Age</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Value</th>
                                <th>Txn Fee</th>
                            </tr>
                        </thead>
                        <tbody>
                            {addressData.transactions.data.map((tx, index) => (
                                <tr key={index}>
                                    <td className="txn-hash"><Link to={"/tx/" + tx.txn_hash} className="see-more-link">{tx.txn_hash.slice(0, 17)}...</Link></td>
                                    <td className="method"><span className="method-tag">{tx.method}</span></td>
                                    <td className="block">{tx.block}</td>
                                    <td className="age">{formatAge(tx.created_at)}</td>
                                    <td className="address"><Link to={"/address/" + tx.from} className="see-more-link">{tx.from.slice(0, 8)}...{tx.from.slice(-8)}</Link></td>
                                    <td className="address"><Link to={"/address/" + tx.to} className="see-more-link">{tx.to.slice(0, 8)}...{tx.to.slice(-8)}</Link></td>
                                    <td className="value">{tx.value} EKA</td>
                                    <td className="fee">{tx.txn_fee}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="widget-footer">
                    <div className="table-pagination">
                        <span className="pagination-item" onClick={() => setPage(1)}>First</span>
                        <span className="pagination-item" onClick={() => setPage(Math.max(1, page - 1))}>&lt;</span>
                        <span className="pagination-text">Page {page} of {addressData.transactions.last_page}</span>
                        <span className="pagination-item" onClick={() => setPage(Math.min(addressData.transactions.last_page, page + 1))}>&gt;</span>
                        <span className="pagination-item" onClick={() => setPage(addressData.transactions.last_page)}>Last</span>
                    </div>
                </div>
            </div>
        </Container>


    );
};

export default Address;