import React from 'react';
import './DataTable.css';
import { Link } from 'react-router-dom';
import NoteIcon from './NoteIcon';
import numeral from 'numeral';

const TransactionDataTable = ({ transactions }) => {
  return (
    <div className="latest-blocks-widget">
      <div className="widget-header">Latest Transactions</div>
      <div className="transactions-list scrollable-table">
        {transactions.length > 0 ? (
          transactions.map((transaction, index) => (
            <div key={index} className="transaction-item">
              <div className="transaction-hash"> <span className='trx-note-icon'><NoteIcon /></span><Link to={"/tx/" + transaction.hash} className="see-more-link">{transaction.hash.slice(0, 10)}...</Link></div>
              <div className="transaction-details">From <span><Link className='see-more-link' to={"/address/" + transaction.from}>{transaction.from.slice(0, 8)}...{transaction.from.slice(-8)}</Link></span></div>
              <div className="transaction-details">To <span><Link className='see-more-link' to={"/address/" + transaction.to}>{transaction.to.slice(0, 8)}...{transaction.to.slice(-8)}</Link></span></div>
              <div className="transaction-amount">
                <span className='block-total-value-label-dented'>{numeral(transaction.value).format("0,0.0000")} EKA </span>
              </div>
            </div>
          ))
        ) : (
          <div className="no-records">No transactions found</div>
        )}
      </div>
      <div className="widget-footer">
        <Link to="txs">VIEW ALL TRANSACTIONS →</Link>
      </div>
    </div>
  );
};

export default TransactionDataTable;