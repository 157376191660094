import React, { useState } from 'react';
import { Form, InputGroup, Button, Dropdown } from 'react-bootstrap';
import './SearchBar.css';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';
import { useNavigate } from 'react-router-dom';

import FloatingAlert from './FloatingAlert';


const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState('All Filters');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${API_ENDPOINTS.SEARCH}?query=${query}&filter=${filter.toLowerCase()}`);
      const { type, data } = response.data;

      if (type === 'address') {
        navigate(`/address/${data}`);
      } else if (type === 'block') {
        // Uncomment this when the single block view route is implemented
        navigate(`/block/${data}`);
        console.log(`Block found: ${data}. Route not implemented yet.`);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setAlertMessage('No results found');
        setShowAlert(true);
      } else {
        console.error('Error performing search:', error);
        setAlertMessage('An error occurred while searching');
        setShowAlert(true);
      }
    }
  };
  return (
    <div className="search-bar-container background-mesh-img ">
      <FloatingAlert
        message={alertMessage}
        show={showAlert}
        onHide={() => setShowAlert(false)}
      />
      <div className="scanner-name mb-3">
        EKA Explorer
      </div>

      <Form onSubmit={handleSearch}>
        <InputGroup>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              {filter}

            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setFilter('All Filters')}>All Filters</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter('Address')}>Address</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter('Block')}>Block</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Form.Control
            type="text"
            placeholder="Search by Address / Block "
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button variant="primary" type="submit">
            <i className="fas fa-search"></i>
          </Button>
        </InputGroup>
      </Form>
    </div>
  );
};

export default SearchBar;