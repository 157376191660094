import React from 'react';

const PixelAvatarIcon = ({ className = '', size = 18 }) => (
  <svg
    viewBox="0 0 80 80"
    fill="none"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    className={className}
  >
    <mask id="mask__pixel" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
      <rect width="80" height="80" rx="160" fill="#FFFFFF" />
    </mask>
    <g mask="url(#mask__pixel)">
      <rect width="10" height="10" />
      <rect x="20" width="10" height="10" fill="#40F314" />
      <rect x="40" width="10" height="10" fill="#FCE502" />
      <rect x="60" width="10" height="10" fill="#FCE502" />
      <rect x="10" width="10" height="10" fill="#FCE502" />
      <rect x="30" width="10" height="10" fill="#40F314" />
      <rect x="50" width="10" height="10" fill="#FCE502" />
      <rect x="70" width="10" height="10" fill="#40F314" />
      <rect y="10" width="10" height="10" fill="#FCE502" />
      <rect y="20" width="10" height="10" fill="#FCE502" />
      <rect y="30" width="10" height="10" fill="#40F314" />
      <rect y="40" width="10" height="10" fill="#40F314" />
      <rect y="50" width="10" height="10" fill="#FCE502" />
      <rect y="60" width="10" height="10" fill="#40F314" />
      <rect y="70" width="10" height="10" fill="#40F314" />
      <rect x="20" y="10" width="10" height="10" fill="#40F314" />
      <rect x="20" y="20" width="10" height="10" fill="#0764EF" />
      <rect x="20" y="30" width="10" height="10" fill="#40F314" />
      <rect x="20" y="40" width="10" height="10" fill="#FCE502" />
      <rect x="20" y="50" width="10" height="10" fill="#0764EF" />
      <rect x="20" y="60" width="10" height="10" fill="#40F314" />
      <rect x="20" y="70" width="10" height="10" fill="#40F314" />
      <rect x="40" y="10" width="10" height="10" fill="#40F314" />
      <rect x="40" y="20" width="10" height="10" fill="#40F314" />
      <rect x="40" y="30" width="10" height="10" fill="#FCE502" />
      <rect x="40" y="40" width="10" height="10" fill="#40F314" />
      <rect x="40" y="50" width="10" height="10" fill="#40F314" />
      <rect x="40" y="60" width="10" height="10" fill="#40F314" />
      <rect x="40" y="70" width="10" height="10" fill="#0764EF" />
      <rect x="60" y="10" width="10" height="10" fill="#0764EF" />
      <rect x="60" y="20" width="10" height="10" fill="#40F314" />
      <rect x="60" y="30" width="10" height="10" fill="#40F314" />
      <rect x="60" y="40" width="10" height="10" fill="#0764EF" />
      <rect x="60" y="50" width="10" height="10" fill="#40F314" />
      <rect x="60" y="60" width="10" height="10" fill="#0764EF" />
      <rect x="60" y="70" width="10" height="10" fill="#40F314" />
      <rect x="10" y="10" width="10" height="10" fill="#FCE502" />
      <rect x="10" y="20" width="10" height="10" fill="#40F314" />
      <rect x="10" y="30" width="10" height="10" fill="#0764EF" />
      <rect x="10" y="40" width="10" height="10" fill="#FCE502" />
      <rect x="10" y="50" width="10" height="10" fill="#40F314" />
      <rect x="10" y="60" width="10" height="10" fill="#0764EF" />
      <rect x="10" y="70" width="10" height="10" fill="#FCE502" />
      <rect x="30" y="10" width="10" height="10" fill="#40F314" />
      <rect x="30" y="20" width="10" height="10" fill="#40F314" />
      <rect x="30" y="30" width="10" height="10" fill="#40F314" />
      <rect x="30" y="40" width="10" height="10" fill="#40F314" />
      <rect x="30" y="50" width="10" height="10" fill="#40F314" />
      <rect x="30" y="60" width="10" height="10" fill="#40F314" />
      <rect x="30" y="70" width="10" height="10" fill="#0764EF" />
      <rect x="50" y="10" width="10" height="10" fill="#40F314" />
      <rect x="50" y="20" width="10" height="10" fill="#0764EF" />
      <rect x="50" y="30" width="10" height="10" fill="#40F314" />
      <rect x="50" y="40" width="10" height="10" fill="#40F314" />
      <rect x="50" y="50" width="10" height="10" fill="#0764EF" />
      <rect x="50" y="60" width="10" height="10" fill="#40F314" />
      <rect x="50" y="70" width="10" height="10" fill="#0764EF" />
      <rect x="70" y="10" width="10" height="10" fill="#40F314" />
      <rect x="70" y="20" width="10" height="10" fill="#40F314" />
      <rect x="70" y="30" width="10" height="10" fill="#0764EF" />
      <rect x="70" y="40" width="10" height="10" fill="#40F314" />
      <rect x="70" y="50" width="10" height="10" fill="#0764EF" />
      <rect x="70" y="60" width="10" height="10" fill="#FCE502" />
      <rect x="70" y="70" width="10" height="10" fill="#40F314" />
    </g>
  </svg>
);

export default PixelAvatarIcon;