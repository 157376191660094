import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import './Header.css';
import EkaLogo from "../ekascan_logo.png";
import SmallSearchBar from './SmallSearchBar';
import { useLocation } from 'react-router-dom';

const Header = () => {

  const location = useLocation();
  const isHomePage = location.pathname === '/' || location.pathname === '/eka_scan' || location.pathname === '/eka_scan';

  return (
    <header className='header-content'>
      <div className="top-bar d-none d-md-block">
        <Container>
          <span className="price">Price: $0.1190 (<i className='fa fa-triangle'></i> 0.0000%)</span>
          <span className="gas">Gas: 30 Gwei</span>
        </Container>
      </div>

      {!isHomePage && <SmallSearchBar />}

      <Navbar bg="dark" className='p-1' variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <div className="logo-placeholder">
              <img src={EkaLogo} alt='' />
            </div>
            {/* ELEKTRACHAIN */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <NavDropdown title="Blockchain" id="blockchain-dropdown">
                <NavDropdown.Item href="/txs">View Txns</NavDropdown.Item>
                <NavDropdown.Item href="/blocks">View Blocks</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Validators" id="validators-dropdown">
                <NavDropdown.Item href="/validators">View Leaderboard </NavDropdown.Item>
         
              </NavDropdown>
              <NavDropdown title="Tokens" id="tokens-dropdown">
                <NavDropdown.Item href="#">View Energy-driven tokens </NavDropdown.Item>
                <NavDropdown.Item href="#tokens/item2">####</NavDropdown.Item>
              </NavDropdown>
             
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <hr className="header-divider" /> */}
    </header>
  );
};

export default Header;