import React from 'react';
import './DataTable.css';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import numeral from 'numeral';



const TimeAgo = ({ timestamp }) => {
  const date = parseISO(timestamp);
  const formatted = formatDistanceToNow(date, { addSuffix: true });
  return formatted.replace('about ', ''); // Remove 'about ' from the string
}


const BlockDataTable = ({ blocks }) => {
  return (
    <div className="latest-blocks-widget data-table-container">
      <div className="widget-header">Latest Blocks</div>
      <div className="blocks-list scrollable-table">



        {blocks.length > 0 ? (
          blocks.map((block, index) => (
            <div className="block-item" key={index}>
              <div className="block-number">Block <span><Link className='see-more-link' to={"/block/" + block.block_number}>{block.block_number}</Link></span> {''}   <TimeAgo timestamp={block.updated_at} /> </div>
              <div className="validator">Validated By <span>Validator:{block.block_validator}</span></div>
              <div className="transactions">
                {block.num_of_trnx_confirmed} txns
                <span className="created-time ml-1">
                  <TimeAgo timestamp={block.created_at} />
                </span>

                <span className='block-total-value-label-dented'>
                    {numeral(block.total_value).format("0,0.0000") + " EKA"}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="no-records">No blocks found</div>
        )}

      </div>
      <div className="widget-footer">
        <Link to="blocks">VIEW ALL BLOCKS →</Link>
      </div>
    </div>
  );
};

export default BlockDataTable;