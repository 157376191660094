// src/config.js
const isDevelopment = process.env.NODE_ENV === 'development';

export const API_BASE_URL = isDevelopment 
    ? 'http://localhost:8000' 
    : 'https://iamconst-m.com/electracoin';

export const API_ENDPOINTS = {
    HOME: `${API_BASE_URL}/api/eka-scan/home`,
    TRANSACTIONS: `${API_BASE_URL}/api/eka-scan/transactions`,
    BLOCKS: `${API_BASE_URL}/api/eka-scan/blocks`,
    VALIDATORS: `${API_BASE_URL}/api/eka-scan/validators`,
    TRANSACTION: `${API_BASE_URL}/api/eka-scan/transaction`,
    TRANSACTION_DETAILS: `${API_BASE_URL}/api/eka-scan/transaction`,
    ADDRESS: `${API_BASE_URL}/api/eka-scan/address`,
    SEARCH: `${API_BASE_URL}/api/eka-scan/search`,
    BLOCK: `${API_BASE_URL}/api/eka-scan/blocks`,
    TOTAL_MINED_STATS: `${API_BASE_URL}/api/eka-scan/total-mined-stats`,






};