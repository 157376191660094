import React, { useEffect, useState } from 'react';
import './ValidatorListing.css';
import validators from './listingValidators.json';
import hashRateIcon from '../hash-rate-logo.svg'
import candidateIcon from '../candidate.svg'
import deligateIcon from '../icon-hash-delegate.svg'
import solanaLogo from '../solana-sol-logo.png'
import ekaLogo from '../eka_logo.png'
import axios from 'axios';
import { API_ENDPOINTS } from '../config';


const ValidatorCard = ({ validator }) => (
    <div className="validator-card">
        <div className="validator-header">
            <img src={solanaLogo} alt="Validator Icon" className="validator-icon" />
            <h3>{validator.name}</h3>
            <div className="status-container">
                <span className="status active">Active</span>
                <span className="status normal">Normal</span>
            </div>
        </div>
        <div className="validator-stats">
            <div className="stat-item">
                <span className="stat-label"><i className="icon"></i>Delegated EKA</span>
                <span className="stat-value">{validator.delegatedEKA} <span className="stat-percentage">{validator.delegatedEKAPercentage}</span></span>
            </div>
            <div className="stat-item">
                <span className="stat-label"><i className="icon"></i>Delegated Hash</span>
                <span className="stat-value">{validator.delegatedHash} <span className="stat-percentage">{validator.delegatedHashPercentage}</span></span>
            </div>
            <div className="stat-item">
                <span className="stat-label"><i className="icon"></i>Delegated USD</span>
                <span className="stat-value">{validator.delegatedUSD} <span className="stat-percentage">{validator.delegatedUSDPercentage}</span></span>
            </div>
            <div className="stat-item">
                <span className="stat-label"><i className="icon"></i>Commission</span>
                <span className="stat-value">{validator.commission}</span>
            </div>
            <div className="stat-item">
                <span className="stat-label"><i className="icon"></i>Hybrid Score</span>
                <span className="stat-value">{validator.hybridScore}</span>
            </div>
            <div className="stat-item">
                <span className="stat-label"><i className="icon"></i>EKA Reward Rate</span>
                <span className="stat-value">{validator.ekaRewardRate}</span>
            </div>
        </div>
    </div>
);


const ValidatorListing = () => {

    const [totalMinedStats, setTotalMinedStats] = useState({
        totalEkaMined: 0,
        totalUsdMined: 0
    });

    useEffect(() => {
        const fetchTotalMinedStats = async () => {
            try {
                const response = await axios.get(`${API_ENDPOINTS.TOTAL_MINED_STATS}`);
                setTotalMinedStats(response.data);
            } catch (error) {
                console.error('Error fetching total mined stats:', error);
            }
        };

        fetchTotalMinedStats();
    }, []);

    return (
        <div className="validator-listing">
            <div className="header-gradient-bg">

            </div>
            <div className="page-header">
                <h1 className='page-title'>Validators</h1>
                <p>Round <span className='title-round-count'>7,321</span></p>
            </div>

            <div className="stats-grid">
                <div className="stat-card">
                    <div className="stat-header">
                        <h2>Total EKA Delegate <span className="info-icon">ⓘ</span></h2>
                        <img className='stat-icon' src={ekaLogo} alt="" />
                    </div>
                    <p className="stat-number">{totalMinedStats.totalEkaMined} <span className="currency-unit">EKA</span></p>
                </div>
                <div className="stat-card">
                    <div className="stat-header">
                        <h2>Total USD Delegate <span className="info-icon">ⓘ</span></h2>
                        <img className='stat-icon' src={solanaLogo} alt="" />
                    </div>
                    <p className="stat-number">{totalMinedStats.totalUsdMined} <span className="currency-unit">USD</span></p>
                </div>

                <div className="stat-card">
                    <div className="stat-header">
                        <h2>Validators <span className="info-icon">ⓘ</span></h2>
                        <img className='stat-icon' src={candidateIcon} alt="" />
                    </div>
                    <p className="stat-number">29 / 40</p>
                </div>

                <div className="stat-card">
                    <div className="stat-header">
                        <h2>Total Hash Delegate <span className="info-icon">ⓘ</span></h2>
                        <img className='stat-icon' src={deligateIcon} alt="" />
                    </div>
                    <p className="stat-number">26 EKA BLOCKS</p>
                </div>

                <div className="stat-card">
                    <div className="stat-header">
                        <h2>Delegated Hash Rate / Bitcoin Total Hash Rate </h2>
                        <img className='stat-icon' src={solanaLogo} alt="" />
                    </div>
                    <p className="stat-number">155 EH/s / 389 EH/s</p>
                </div>

                <div className="stat-card staking-card">
                    <div className="stat-header">
                        <h2>Liquid Staking EKA Reward Rate <span className="info-icon">ⓘ</span></h2>
                        <img className='stat-icon-last' src={ekaLogo} alt="" />
                    </div>
                    <div className="stat-content">
                        <p className="stat-number">Above 2.5%</p>
                        <button className="mint-button">
                            Mint <span className="arrow-icon">→</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="validators-grid">
                {validators.map((validator, index) => (
                    <ValidatorCard key={index} validator={validator} />
                ))}
            </div>
        </div>
    );
};

export default ValidatorListing;