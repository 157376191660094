import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Clock, ChevronLeft, ChevronRight } from 'lucide-react';
import axios from 'axios';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { API_ENDPOINTS } from '../config';
import './SingleBlock.css';
import InfoCircleIcon from './InfoCircleIcon';

const DetailItem = ({ title, value, additionalInfo, icon, className }) => (
  <Row className={`detail-item ${className || ''}`}>
    <Col xs={12} md={3} className="detail-title">
      {icon && <span className="icon">{icon}</span>}
      {title}
    </Col>
    <Col xs={12} md={9} className="detail-value">
      <div className="value-wrapper">
        <span >{value}</span>
      </div>
      {additionalInfo && <div className="additional-info">{additionalInfo}</div>}
    </Col>
  </Row>
);

const SingleBlock = () => {
  const { blockId } = useParams();
  const [blockData, setBlockData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlockData = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINTS.BLOCKS}/${blockId}`);
        setBlockData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch block details');
        setLoading(false);
      }
    };

    fetchBlockData();
  }, [blockId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!blockData) return <div>Block not found</div>;

  return (
    <Container fluid className="transaction-container">
      <h2 className="transaction-title">Block #{blockData.block_number}</h2>
      <div className="transaction-card">
        <DetailItem
          title={
                <>
                <InfoCircleIcon size='12'  />
                <span className='mx-1 text-bold'>Block Height</span>
                </> 
                }
        value={
          <>
            {blockData.block_number}
            <Link to={`/block/${blockData.block_number - 1}`}><ChevronLeft size={16} /></Link>
            <Link to={`/block/${blockData.block_number + 1}`}><ChevronRight size={16} /></Link>
          </>
        }
                />
        <DetailItem
          title="Timestamp"
          value={
            <>
             <Clock size={12}  />
             <span className='mx-1 '>{formatDistanceToNow(parseISO(blockData.timestamp), { addSuffix: true }).replace("about", "")}</span>
            </>
            
          }
          additionalInfo={`(${blockData.timestamp})`}
          icon={<InfoCircleIcon  />}
        />
        <DetailItem
          title="Transactions"
          value={`${blockData.num_of_trnx} transactions in this block`}
          className="highlight"
          icon={ <InfoCircleIcon size='12'  />}
        />
        <hr className="divider" />

        <DetailItem
          title="Validated by:"
          value={
            <>
            <Link className='see-more-link me-1' to={'/address/' + blockData.wallet_address}>{blockData.wallet_address}</Link>

           
            {/* (Validator: ${blockData.validator_name}) */}
            (Validator: {blockData.validator_name}) in {blockData.confirmation_time} secs
            </>
            
          }

          icon={ <InfoCircleIcon size='12'  />}

        />
        <DetailItem
          title="Block Reward"
          value={`${blockData.block_reward} EKA`}
          icon={ <InfoCircleIcon size='12'  />}

        />
        <DetailItem
          title="Difficulty"
          value={blockData.difficulty}
          icon={ <InfoCircleIcon size='12'  />}

        />
        <DetailItem
          title="Total Difficulty"
          value={blockData.total_difficulty.toLocaleString()}
          icon={ <InfoCircleIcon size='12'  />}

        />
        <DetailItem
          title="Size"
          value={`${blockData.size.toLocaleString()} bytes`}
          icon={ <InfoCircleIcon size='12'  />}

        />
        <hr className="divider" />

        <DetailItem
          title="Gas Used"
          value={`${blockData.gas_used.toLocaleString()} (${blockData.gas_used_percentage}%)`}
          icon={ <InfoCircleIcon size='12'  />}

        />
        <DetailItem
          title="Gas Limit"
          value={blockData.gas_limit.toLocaleString()}
          icon={ <InfoCircleIcon size='12'  />}

        />
      </div>
    </Container>
  );
};

export default SingleBlock;