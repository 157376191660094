import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Clock, Copy } from 'lucide-react';
import './ContactUs.css';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';
import { Link } from 'react-router-dom';


const DetailItem = ({ title, value, additionalInfo, icon, copyable, onCopy, className }) => (
    <Row className={`detail-item ${className || ''}`}>
        <Col xs={12} md={3} className="detail-title">
            {icon && <span className="icon">{icon}</span>}
            {title}
        </Col>
        <Col xs={12} md={9} className="detail-value">
            <div className="value-wrapper">
                <span>{value}</span>
                {copyable && (
                    <button className="copy-button" onClick={() => onCopy(value)}>
                        <Copy size={16} />
                    </button>
                )}
            </div>
            {additionalInfo && <div className="additional-info">{additionalInfo}</div>}
        </Col>
    </Row>
);

const ContactUs = () => {



    return (
        <Container fluid className="contact-container">
            <h2 className="contact-title">Contact Us</h2>
            <hr className='divider' />
            <div className="contact-card">
                <DetailItem title="Feedback Form" />
                <hr className="divider" />

                <DetailItem
                    title=""
                    value={
                        <>
                            <div className="feed-back-container">
                                <p>Please understand that we can only assist with issues directly related
                                    to the block explorer services. Before writing in, please be informed that:
                                </p>

                                <p>
                                    <ol>
                                        <li>
                                            <span> Pending Transaction ⏳</span> <br />
                                            We do not process transactions and are therefore unable to expedite, cancel or
                                            replace them. If you are having issues with pending transactions, you could review
                                            "Gas Fee”,  which is commonly called a "Transaction Fee", is a small incentive or fee
                                            that is paid to block miners to include your transaction in the Ethereum Blockchain.
                                            The unit of “Gas Fee” is called Gwei and 1 Gwei is equivalent to 0.001 EKA.
                                            The way we calculate how much gas fee to pay for a transaction is by multiplying “Gas Price”
                                            with “Gas Used.” Do not confuse “Gas Used” with “Gas Limit.” “Gas Used” is the total amount of
                                            gas used in the transaction while “Gas Limit” is the maximum number of gas the transaction is allowed to use by the sender.
                                        </li>

                                        <li>
                                            <span>Network Congestion</span> <br />
                                            Network congestion plays an important role in the amount of gas price users would need to pay and the speed of the transaction. Pending transactions on the Elektrachain network determine how congested the network is.
                                            The more congested the network is, the higher the gas price users would need to pay to secure their transactions on the blockchain.
                                            Miners will favor transactions that have a higher gas price thus including transactions that pay a higher gas price first before those with a lower gas price.
                                        </li>
                                        <li>
                                            <span>Elektrachain Block Explorer 🔍</span> <br />
                                            Eka-scan is an independent block explorer unrelated to other service providers (unless stated explicitly otherwise) and is therefore unable to provide a precise response for inquiries that are specific to other service providers.
                                        </li>
                                        <li>
                                            <span>Knowledge Base 📚</span> <br />
                                            Our Articles on documentation of our chain can be accessed via the links either on the Elektrachain website or its scan. our Knowledge Base page provides guidelines on what to expect and how to properly use Elektrachain.
                                        </li>
                                        <li>
                                            <span>Community Support 👋</span> <br />
                                            We will never ask for your private keys or passwords. NEVER send us these. While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not spam us.
                                        </li>
                                        <li>
                                            <span>Wallet / Exchange / Project related issues 🏦</span> <br />
                                            Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.
                                        </li>

                                    </ol>
                                </p>

                            </div>

                            <div className="note">
                                Forward all complaints to support@eka-scan.com

                                Note: Selecting an incorrect subject could result in a delayed or non response
                            </div>
                            <hr className='divider' />

                        </>
                    }
                />



            </div>


        </Container>


    );
};

export default ContactUs;