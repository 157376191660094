import React from 'react';

const NoteIcon = ({ className = '', size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    className={`iconify iconify--quill ${className}`}
    width={size}
    height={size}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 32 32"
  >
    <g fill="none" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 9h4m-4 7h12m-12 4h12m-12 4h4m-6 5h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2"
      />
      <circle cx="22" cy="9" r=".5" fill="currentColor" />
    </g>
  </svg>
);

export default NoteIcon;