import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Clock, Copy } from 'lucide-react';
import './SingleTransaction.css';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { API_ENDPOINTS } from '../config';
import axios from 'axios';


const DetailItem = ({ title, value, additionalInfo, icon, copyable, onCopy, className }) => (
    <Row className={`detail-item ${className || ''}`}>
        <Col xs={12} md={3} className="detail-title">
            {icon && <span className="icon">{icon}</span>}
            {title}
        </Col>
        <Col xs={12} md={9} className="detail-value">
            <div className="value-wrapper">
                <span>{value}</span>
                {copyable && (
                    <button className="copy-button" onClick={() => onCopy(value)}>
                        <Copy size={16} />
                    </button>
                )}
            </div>
            {additionalInfo && <div className="additional-info">{additionalInfo}</div>}
        </Col>
    </Row>
);

const SingleTransaction = () => {
    const { trnxHash } = useParams();
    const [transaction, setTransaction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        // You might want to add a toast notification here
    };

    useEffect(() => {
        const fetchTransactionDetails = async () => {
            try {
                const response = await axios.get(`${API_ENDPOINTS.TRANSACTION_DETAILS}/${trnxHash}/details`);
                setTransaction(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch transaction details');
                setLoading(false);
            }
        };

        fetchTransactionDetails();
    }, [trnxHash]);


    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!transaction) return <div>Transaction not found</div>;


    return (
        <Container fluid className="transaction-container">
            <h2 className="transaction-title">Transaction Details</h2>
            <div className="transaction-card">
                <DetailItem
                    title="Transaction Hash"
                    value={transaction.hash}
                    copyable
                    onCopy={() => copyToClipboard(transaction.hash)}
                />
                <DetailItem
                    title="Status"
                    value={
                        <>
                            <div className='success-span'>
                                <i className='fa fa-check-circle  mx-1'></i>{transaction.status.toLowerCase()}
                            </div>
                        </>
                    }
                    className={`status-${transaction.status.toLowerCase()}`}
                />
                <DetailItem
                    title="Block"
                    value={
                        <>
                            <span className='single-trx-block-num'>
                            <Link className='see-more-link' to={'/block/' + transaction.block_number}> {transaction.block_number}</Link>
                               
                            </span>
                            <span className='block-total-value-label-dented'>
                                {transaction.block_confirmation} Block Confirmation{transaction.block_confirmation !== 1 ? 's' : ''}
                            </span>
                        </>

                    }

                />
                <DetailItem
                    title="Timestamp"
                    value={`${formatDistanceToNow(parseISO(transaction.timestamp))} ago (${transaction.timestamp})`}
                    icon={<Clock size={16} />}
                />
                <hr className="divider" />
                <DetailItem
                    title="From"
                    value={
                        <>
                            <Link className='see-more-link' to={'/address/' + transaction.from}>{transaction.from}</Link>
                        </>

                    }
                    additionalInfo={`(Validator: ${transaction.from_validator})`}
                    copyable
                    onCopy={() => copyToClipboard(transaction.from)}
                />
                <DetailItem
                    title="To"
                    value={
                        <>
                            <Link className='see-more-link' to={'/address/' + transaction.to}>{transaction.to}</Link>
                        </>


                    }
                    copyable
                    onCopy={() => copyToClipboard(transaction.to)}
                />
                <hr className="divider" />
                <DetailItem
                    title="Value"
                    value={`${transaction.value} EKA`}
                />
                <DetailItem
                    title="Transaction Fee"
                    value={`${transaction.transaction_fee} EKA`}
                />
            </div>
        </Container>
    );
};

export default SingleTransaction;