import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="site-footer">
      <Container>
        <Row>
          <Col md={6} className='background-mesh-img-left'>
            <div className="powered-by">
              <h5>Powered by ElektraPay</h5>
              <p>Eka Scan is a Block Explorer and Analytics Platform for Elektrachain</p>
              <div className="button-group">
                <button className="btn btn-outline-light btn-sm">Add EKA Network</button>
                <button className="btn btn-outline-light btn-sm btn-icon">🌙</button>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <Row className='p-3'>
              <Col md={4} className='footer-nav-listing'>
                <h6>ELEKTRACHAIN</h6>
                <ul className="list-unstyled">
                  <li><a href="/contact-us">Contact Us</a></li>
                  <li><a href="/terms">Terms</a></li>
                  <li><a href="/privacy-policy">Privacy</a></li>
                </ul>
              </Col>
              <hr className='divider' />

              <Col md={4} className='footer-nav-listing'>
                <h6>Products</h6>

                <ul className="list-unstyled">
                  <li><a href="/stake">Validators</a></li>
                </ul>
              </Col>
              {/* <Col md={4} className='footer-nav-listing'>
                <h6>Community</h6>
                <ul className="list-unstyled">
                </ul>
              </Col> */}
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="text-center">
            <p>Elektrachain &copy; 2024 EKA Explorer. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;