import React, { useEffect } from 'react';
import './FloatingAlert.css';

const FloatingAlert = ({ message, show, onHide }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onHide();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onHide]);

  if (!show) return null;

  return (
    <div className="floating-alert">
      {message}
    </div>
  );
};

export default FloatingAlert;