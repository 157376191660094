import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { API_ENDPOINTS } from '../config';
import SearchBar from './SearchBar';
import InfoCard from './InfoCard';
import BlockDataTable from './BlockDataTable';
import TransactionDataTable from './TransactionDataTable';
import './Home.css';

function Home() {
  const [homeData, setHomeData] = useState({
    ekaPrice: 0,
    priceChange: 0,
    marketCap: 0,
    circulatingSupply: 0,
    latestBlock: 0,
    activeValidators: 0,
    transactionHistory: [],
    blockData: [],
    latestTransactions: []
  });

  useEffect(() => {
    fetch(API_ENDPOINTS.HOME)
      .then(response => response.json())
      .then(data => setHomeData(data))
      .catch(error => {
        console.error('Error fetching home data:', error);
        // You might want to set some default or error state here
      });
  }, []);

  return (
    <Container>
      <SearchBar />
      <div className="float-up-content">
        <InfoCard data={homeData} />
        <div className="data-tables-wrapper">
          <BlockDataTable blocks = {homeData.blockData} />
          <TransactionDataTable transactions={homeData.latestTransactions} />
        </div>
      </div>
    </Container>
  );
}

export default Home;